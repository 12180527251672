import React from 'react';
import FeaturesItem from './FeaturesItem';

const Features =()=>{
    return (
      <section className="hosting_features_area">
        <div className="container">
          <FeaturesItem
            hImage="parcel.png"
            hTitle="Delivery software trusted by courier companies and their drivers"
            hPragraph="Movebot empowers couriers and their drivers by offering reliable, effective, and efficient courier services with 24/7 digital tracking and delivery platforms to reduce the cost and stress of parcel transportation."
            icon2="icon2.png"
            icon="icon3.png"
            icon3="icon4.png"
            text="Deliver more and fast."
            text2="Delight and retain your clients."
            text3="Connect easy with new clients."
            tdescription="Empower your drivers/riders, collaborate with reliable couriers using Movebot, and send your parcel/cargo without hassles 24/7."
            tdescription2="Deliver unmatched services to your clients, communicate transparently about your offers, update seamlessly and timely about the status of parcels with Movebot and enjoy clients’ continuous patronage."
            tdescription3="Meet new clients daily on Movebot and grow your client base. You determine when to stop adding new clients."
          />
        </div>
      </section>
    );
}

export default Features;
