import React from "react";

const TermsOfServiceItemFour = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
            
                The Client can select and order the Services through the Movebot
                Platform. The Client may not withdraw from or cancel an Order.
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.7s">
          
                The Client has to pay the Service Fee to the Provider in the
                amount and means that have been agreed with the respective
                service provider on the Movebot Platform upon the confirmation
                of the Order. The Service Fee on the Movebot Platform may be
                changed from time to time before making an Order as deemed
                necessary by the service provider.
    
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.9s">
         
                Movebot is a platform provider and does not collect payment on
                behalf of the service providers. In essence, the respective
                service provider is obliged to collect payment and by any means
                she deems appropriate.
        
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemFour;
