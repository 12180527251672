import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { css } from "@emotion/css";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import appVariables from "../../variables";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { Alert } from "antd";
import "./contact.css";

const enterValidEmail = "Please, enter a valid email address";
const requestError = "Error completing request";
const validationSchema = yup.object().shape({
  name: yup.string().required("Your name is required"),
  emailAddress: yup
    .string()
    .email(enterValidEmail)
    .required("Email address is required"),
  message: yup.string().required("Message is required"),
});

const initialValues = {
  name: "",
  emailAddress: "",
  message: "",
};

const classes = {
  alert: css({
    display: "flex",
    justifyContent: "center",

    "@media (min-width: 1148px)": {
      margin: "0px 0px 48px 0px !important",
    },

    "@media (max-width: 767px)": {
      marginBottom: "16px !important",
    },
  }),
  inputError: css({
    marginTop: "8px",
    color: "#f5222d",
    fontSize: "14px",
  }),
};

const Contacts = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Form Submission
  const onSubmit = async (data) => {
    const reqData = {
      name: data.name,
      emailAddress: data.emailAddress,
      message: data.message,
    };

    setLoading(true);
    setError("");
    setSuccessMessage("");

    await axios
      .post(`${appVariables.serverURL}/LandingPage/TalkToUs`, reqData)
      .then((response) => {
        if (response) {
          setSuccessMessage(getEndpointResultMessage(response.data));
          formik.resetForm();
        }
      })
      .catch((error) => {
        if (error.response) {
          setError(getEndpointResultMessage(error.response.data));
        } else {
          setError(requestError);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <section className="contact_info_area sec_pad bg_color">
      {successMessage && (
        <div className={classes.alert}>
          <Alert
            type="success"
            message={successMessage}
            className="container"
          />
        </div>
      )}
      {error && (
        <div className={classes.alert}>
          <Alert type="error" message={error} className="container" />
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="contact_info_item">
              <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                Office Address
              </h6>
              <p className="f_400 f_size_15">
                {" "}
                18, Olumo Street, Unilag Second Gate Road, Onike, Lagos, Nigeria
              </p>
            </div>
            <div className="contact_info_item">
              <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                Contact Info
              </h6>
              <p className="f_400 f_size_15">
                <span className="f_400 t_color3">Phone:</span>{" "}
                <a href="tel:2347069992100">+234-7069992100</a>
              </p>
              <p className="f_400 f_size_15">
                <span className="f_400 t_color3">Email:</span>{" "}
                <a href="mailto:support@movebot.ng">support@movebot.ng</a>
              </p>
            </div>
          </div>

          <div className="contact_form col-lg-9">
            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
              Leave a Message
            </h2>

            <form onSubmit={formik.handleSubmit} className="contact_form_box">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <input
                      autoFocus
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Your Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      disabled={isLoading}
                    />
                    {formik.touched.name && (
                      <div className={classes.inputError}>
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      name="emailAddress"
                      id="emailAddress"
                      placeholder="Your Email Address"
                      value={formik.values.emailAddress}
                      disabled={isLoading}
                    />
                    {formik.touched.emailAddress && (
                      <div className={classes.inputError}>
                        {formik.errors.emailAddress}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group text_box">
                    <textarea
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="message"
                      id="message"
                      value={formik.values.message}
                      placeholder="Write your message..."
                      disabled={isLoading}
                    ></textarea>
                    {formik.touched.message && (
                      <div
                        className={classes.inputError}
                        style={{ marginTop: "0px" }}
                      >
                        {formik.errors.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn_three btn_msg movebot-icon-btn"
                disabled={isLoading}
              >
                {isLoading && <LoadingOutlined />}
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
