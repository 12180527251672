import React from "react";
import { Space } from "antd";
import { appDimensions } from "../../components/utils/theme";
import ImageProvider from "../../components/utils/ImageProvider";
import LabeledNode from "../../components/utils/LabeledNode";
import { IReviewInfo } from "../../global/review";

export interface IReviewOrderItemSectionProps {
  reviewInfo: IReviewInfo;
}

const ReviewOrderItemSection: React.FC<IReviewOrderItemSectionProps> = (
  props
) => {
  const { reviewInfo } = props;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <LabeledNode
        nodeIsText
        direction="vertical"
        label="Order Number"
        node={reviewInfo.OrderItemNumber}
        style={{ width: "100%" }}
        spaceSize={0}
      />
      <LabeledNode
        nodeIsText
        direction="vertical"
        label="Order Name"
        node={reviewInfo.OrderItemName}
        style={{ width: "100%" }}
        spaceSize={0}
      />
      <ImageProvider
        id={reviewInfo.OrderItemId}
        imageAltBase={`${reviewInfo.OrderItemName} order attachment`}
        height={appDimensions.imageWidth}
        width={appDimensions.imageWidth}
      />
    </Space>
  );
};

export default ReviewOrderItemSection;
