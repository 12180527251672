import path from "path-browserify";

const appVariables = {
  environment: process.env.NODE_ENV,
  serverURL: "https://admin.movebot.ng/prod_sup/api",
  basePath: process.env.BASE_PATH || "",
  loginURL: "https://logistic-dev.movebot.ng/",

  messageDuration: 7,
};

export default appVariables;

export const appPaths = {
  home: path.normalize(`${appVariables.basePath}/`),
  about: path.normalize(`${appVariables.basePath}/About`),
  contact: path.normalize(`${appVariables.basePath}/Contact`),
  partner: path.normalize(`${appVariables.basePath}/signup-for-trial`),
  terms: path.normalize(`${appVariables.basePath}/terms-of-service`),
  policy: path.normalize(`${appVariables.basePath}/privacy-policy`),
  tracking: path.normalize(`${appVariables.basePath}/details`),
  reviews: path.normalize(`${appVariables.basePath}/reviews`),
};
