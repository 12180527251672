import React from "react";

const TermsOfServiceItemNine = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
              
                Movebot reserves the right to make changes to the General Terms
                at any time, by uploading the revised General Terms onto the
                Movebot Platform and notifying all Users thereof through the
                email address provided by the Client during signup. Movebot may
                also make changes to the Additional Terms by notifying the
                Client thereof (where applicable).
            
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
           
                The use of the Movebot Platform and all legal relations formed
                thereunder will be governed by the laws of Nigeria. A Client who
                is a consumer also enjoys the protection of the mandatory
                provisions of the law that would be applicable in the absence of
                this term.
           
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
             
                If the respective dispute resulting from these General Terms
                cannot be settled by negotiations, then the dispute will be
                finally solved in Nigeria Court of law.
            
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
          
                Where versions of these General Terms exist in any other
                language, the English language version shall prevail.
             
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemNine;
