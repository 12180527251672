import React from "react";
import CustomNavbar from "../components/Navbar";
import PrototypeFooter from "../components/Footer/PrototypeFooter";
import FooterData from "../components/Footer/FooterData";
import Breadcrumb from "../components/Breadcrumb";

const PrivacyPloicy = () => {
  return (
    <>
      <CustomNavbar />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Privacy Policy"
      />
      <section className="service_details_area sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 pr_70">
              <div className="job_info">
                <div className="info_head">
                  <i className="ti-receipt"></i>
                  <h6 className="f_p f_600 f_size_18 t_color3">
                    Privacy Policy
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="details_content">
                <div className="sec_title">
                  <p>
                    {" "}
                    Welcome, and thank you for your interest in Movebot
                    (“Movebot”, “we,” or “us”), our website at
                    https://www.movebot.ng. (the “Site”), and all related
                    websites, downloadable software, mobile applications
                    (including tablet applications), and other services provided
                    by us and on which a link to this Privacy Policy is
                    displayed, and all other communications with individuals
                    through from written or oral means, such as email or phone
                    (collectively, together with the Site, our “Service”).This
                    Privacy Policy (“Policy”) describes the information that we
                    gather on or through the Service, how we use and disclose
                    such information, and the steps we take to protect such
                    information. By visiting the Site, or by purchasing or using
                    the Service, you accept the privacy practices described in
                    this Policy. This Policy is incorporated into and is subject
                    to, the Movebot Terms of Service. Capitalized terms used but
                    not defined in this Policy have the meaning given to them in
                    the Movebot Terms of Service.
                  </p>
                  <p className="f_400 f_size_15">
                    <h2
                      style={{
                        color: "#0f7173",
                        fontWeight: 600,
                        fontSize: "18px",
                        margin: "0px 0px 20px 0px",
                      }}
                    >
                      1. DEFINITIONS
                    </h2>
                    “User” means a customer or clients of the customer using
                    Movebot. “User Data“ means personal data, reports,
                    addresses, and other files, folders, or documents in
                    electronic form that a User of the Service stores within the
                    Service. “Personal Data” means any information relating to
                    an identified or identifiable natural person. “Public Area”
                    means the area of the Site that can be accessed both by
                    Users, Partners, and Visitors, without needing to log in.
                    “Restricted Area” means the area of the Site that can be
                    accessed only by Users, and where access requires logging
                    in. “Partner” means an employee, service provider or agent,
                    or representative of a Client, who primarily uses the
                    restricted areas of the Site to access the Service in such
                    capacity. “Visitor” means an individual other than a User,
                    who uses the public area but has no access to the restricted
                    areas of the Site or Service.{" "}
                  </p>

                  <h2
                    style={{
                      color: "#0f7173",
                      fontWeight: 600,
                      fontSize: "18px",
                      margin: "20px 0px 20px 56px",
                    }}
                  >
                    2. THE INFORMATION WE COLLECT ON THE SERVICE:
                  </h2>
                  <p className="f_400 f_size_15">
                    We collect different types of information from or through
                    the Service. The legal bases for Movebot’s processing of
                    personal data are primarily that the processing is necessary
                    for providing the Service in accordance with Movebot’s Terms
                    of Service and that the processing is carried out in
                    Movebot’s legitimate interests, which are further explained
                    in the section “How We Use the Information We Collect” of
                    this Policy. We may also process data upon your consent,
                    asking for it as appropriate.
                  </p>
                  <h2
                    style={{
                      color: "#0f7173",
                      fontWeight: 600,
                      fontSize: "18px",
                      margin: "20px 0px 20px 56px",
                    }}
                  >
                    3. HOW WE USE THE INFORMATION WE COLLECT:
                  </h2>
                  <p className="f_400 f_size_15">
                    We use the information that we collect in a variety of ways
                    in providing the Service and operating our business,
                    including the following: 3.1 Operations We use the
                    information – other than Client Data - to operate, maintain,
                    enhance and provide all features of the Service, to provide
                    the services and information that you request, to respond to
                    comments and questions, and to provide support to users of
                    the Service. We process Client/user Data solely in
                    accordance with the directions provided by the applicable
                    Client or User. 3.2 Improvements We use the information to
                    understand and analyze the usage trends and preferences of
                    our Visitors, Partners, and Users, to improve the Service,
                    and to develop new products, services, features, and
                    functionality. Should this purpose require Cleanagent to
                    process Client Data, then the data will only be used in
                    anonymized or aggregated form. 3.3 Communications We may use
                    a Visitor’s, Partner’s or User’s email address or other
                    information – other than Client Data – to contact that
                    Visitor or Partner or User (i) for administrative purposes
                    such as customer service, to address intellectual property
                    infringement, right of privacy violations or defamation
                    issues related to the Client Data or Personal Data posted on
                    the Service or (ii) with updates on promotions and events,
                    relating to products and services offered by us and by third
                    parties we work with. 3.4 Cookies and Tracking Technologies
                    We use automatically-collected information and other
                    information collected on the Service through cookies and
                    similar technologies to (i) personalize our Service, such as
                    remembering a User’s or Visitor’s information so that the
                    User or Visitor will not have to re-enter it during a visit
                    or on subsequent visits; (ii) provide customized
                    advertisements, content, and information; (iii) monitor and
                    analyze the effectiveness of Service and third-party
                    marketing activities; (iv) monitor aggregate site usage
                    metrics such as the total number of visitors and pages
                    viewed; and (v) track your entries, submissions, and status
                    in any promotions or other activities on the Service. You
                    can obtain more information about cookies by visiting
                    http://www.allaboutcookies.org. 3.5 Analytics We use Google
                    Analytics to measure and evaluate access to and traffic on
                    the Public Area of the Site and create user navigation
                    reports for our Site administrators. Google operates
                    independently from us and has its own privacy policy, which
                    we strongly suggest you review. Google may use the
                    information collected through Google Analytics to evaluate
                    Users' and Visitors’ activity on our Site. For more
                    information, see Google Analytics Privacy and Data Sharing.
                    We take measures to protect the technical information
                    collected by our use of Google Analytics. The data collected
                    will only be used on a need-to-know basis to resolve
                    technical issues, administer the Site and identify visitor
                    preferences; but in this case, the data will be in
                    non-identifiable form. We do not use any of this information
                    to identify Visitors or Users. 3.6 Additional Limits on Use
                    of Your Google User Data Notwithstanding anything else in
                    this Privacy Policy, if you provide the App access to your
                    Google data, the App's use of that data will be subject to
                    these additional restrictions: The App will only use access
                    to read, write, modify or control Gmail message bodies
                    (including attachments), metadata, headers, and settings to
                    provide a web email client that allows users to compose,
                    send, read,and process emails and will not transfer this
                    Gmail data to others unless doing so is necessary to provide
                    and improve these features, comply with applicable law, or
                    as part of a merger, acquisition, or sale of assets. The App
                    will not use this Gmail data for serving advertisements. The
                    App will not allow humans to read this data unless we have
                    your affirmative agreement for specific messages, doing so
                    is necessary for security purposes such as investigating
                    abuse, to comply with applicable law, or for the App's
                    internal operations, and even then only when the data have
                    been aggregated and anonymized.
                  </p>
                  <h2
                    style={{
                      color: "#0f7173",
                      fontWeight: 600,
                      fontSize: "18px",
                      margin: "20px 0px 20px 56px",
                    }}
                  >
                    4.0 TO WHOM WE DISCLOSE INFORMATION
                  </h2>
                  <p className="f_400 f_size_15">
                    Except as described in this Policy, we will not
                    intentionally disclose the Personal Data or Client Data that
                    we collect or store on the Service to third parties without
                    the consent of the applicable Visitor, User, or Client. We
                    may disclose information to third parties if you consent to
                    us doing so, as well as in the following circumstances: 4.1
                    Unrestricted Information Any information that you
                    voluntarily choose to include in a Public Area of the
                    Service, such as a public profile page, will be available to
                    any Visitor or User who has access to that content. 4.2
                    Service Providers We work with third-party service providers
                    who provide website, application development, hosting,
                    maintenance, and other services for us. These third parties
                    may have access to, or process Personal Data or Client Data
                    as part of providing those services for us. We limit the
                    information provided to these service providers to that
                    which is reasonably necessary for them to perform their
                    functions, and our contracts with them require them to
                    maintain the confidentiality of such information. 4.3
                    Non-Personally Identifiable Information We may make certain
                    automatically-collected, aggregated, or otherwise
                    non-personally-identifiable information available to third
                    parties for various purposes, including (i) compliance with
                    various reporting obligations; (ii) for business or
                    marketing purposes; or (iii) to assist such parties in
                    understanding our Clients’, Users’ and Visitors’ interests,
                    habits, and usage patterns for certain programs, content,
                    services, and/or functionality available through the
                    Service, all of the foregoing being subject to additional
                    limits on the use of your Google User data as stated in this
                    Privacy Policy. 4.4 Law Enforcement, Legal Process, and
                    Compliance_ We may disclose Personal Data or other
                    information if required to do so by law or in the good-faith
                    belief that such action is necessary to comply with
                    applicable laws, in response to a facially valid court
                    order, judicial or other government subpoena or warrant, or
                    to otherwise cooperate with law enforcement or other
                    governmental agencies. We also reserve the right to disclose
                    Personal Data or other information that we believe, in good
                    faith, is appropriate or necessary to (i) take precautions
                    against liability, (ii) protect ourselves or others from
                    fraudulent, abusive, or unlawful uses or activity, (iii)
                    investigate and defend ourselves against any third-party
                    claims or allegations, (iv) protect the security or
                    integrity of the Service and any facilities or equipment
                    used to make the Service available, or (v) protect our
                    property or other legal rights, enforce our contracts, or
                    protect the rights, property, or safety of others. 4.5
                    Change of Ownership Information about Users, Partners, and
                    Visitors, including Personal Data, may be disclosed and
                    otherwise transferred to an acquirer, successor or assignee
                    as part of any merger, acquisition, debt financing, sale of
                    assets, or similar transaction, as well as in the event of
                    an insolvency, bankruptcy, or receivership in which
                    information is transferred to one or more third parties as
                    one of our business assets and only if the recipient of the
                    User or Partner Visitor Data commits to a Privacy Policy
                    that has terms substantially consistent with this Privacy
                    Policy. Client Data may be physically or electronically
                    transferred to an acquirer, or successor, or assignee as
                    part of any merger, acquisition, debt financing, sale of
                    assets, or similar transaction, as well as in the event of
                    an insolvency, bankruptcy, or receivership in which
                    information is transferred to one or more third parties as
                    one of our business assets, for the sole purpose of
                    continuing the operation of the Service, and only if the
                    recipient of the Client Data commits to a Privacy Policy
                    that has terms substantially consistent with this Privacy
                    Policy.
                  </p>
                  <h2
                    style={{
                      color: "#0f7173",
                      fontWeight: 600,
                      fontSize: "18px",
                      margin: "20px 0px 20px 56px",
                    }}
                  >
                    5.0 DATA RETENTION
                  </h2>
                  <p className="f_400 f_size_15">
                    We only retain the Personal Data collected from a User for
                    as long as the User’s account is active or otherwise for a
                    limited period as long as we need it to fulfill the purposes
                    for which we have initially collected it unless otherwise
                    required by law. We will retain and use information as
                    necessary to comply with our legal obligations, resolve
                    disputes, and enforce our agreements.
                  </p>
                  <h2
                    style={{
                      color: "#0f7173",
                      fontWeight: 600,
                      fontSize: "18px",
                      margin: "20px 0px 20px 56px",
                    }}
                  >
                    6.0 HOW TO CONTACT US
                  </h2>
                  <p className="f_400 f_size_15">
                    Please contact us with any questions or comments about this
                    Policy, your Personal Data, our use and disclosure
                    practices, or your consent choices by email at
                    support@movebot.ng If you have any concerns or complaints
                    about this Policy or your Personal Data.
                  </p>
                  <h2
                    style={{
                      color: "#0f7173",
                      fontWeight: 600,
                      fontSize: "18px",
                      margin: "20px 0px 20px 56px",
                    }}
                  >
                    7.0 CHANGES AND UPDATES TO THIS POLICY
                  </h2>
                  <p className="f_400 f_size_15">
                    Please revisit this page periodically to stay aware of any
                    changes to this Policy, which we may update from time to
                    time. If we modify the Policy, we will make it available
                    through the Service, and indicate the date of the latest
                    revision, and will comply with applicable law. Your
                    continued use of the Service after the revised Policy has
                    become effective indicates that you have read, understood,
                    and agreed to the current version of the Policy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PrototypeFooter FooterData={FooterData} />
    </>
  );
};
export default PrivacyPloicy;
