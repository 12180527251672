import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import Modal from "../components/PopupModal/ModalScreen";
import appVariables, { appPaths } from "../variables";

const Navbar = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  let { mClass, nClass, cClass, slogo, hbtnClass } = props;
  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className="header_area">
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <Link className={`navbar-brand ${slogo}`} to={appPaths.home}>
              <img src={require("../img/MoveBot.png")} alt="" />
              <img src={require("../img/MoveBot.png")} alt="logo" />
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className={`navbar-nav menu ml-auto ${nClass}`}
                style={{
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <li className="nav-item">
                  <NavLink
                    exact
                    title="About"
                    className="nav-link"
                    to={appPaths.home}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact
                    title="About"
                    className="nav-link"
                    to={appPaths.about}
                  >
                    About
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    title="Contact"
                    className="nav-link"
                    to={appPaths.contact}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <a
                href={appVariables.loginURL}
                className={`btn_get btn_hover ${hbtnClass}`}
              >
                Portal Login
              </a>
            </div>
          </div>
        </nav>
      </header>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
    </Sticky>
  );
};

export default Navbar;
