import * as yup from "yup";
import React from "react";
import { FormikHelpers, useFormik } from "formik";
import { appMessages } from "../../global/messages";
import { css } from "@emotion/css";
import FormItemError from "../../components/utils/FormItemError";
import { Input, Rate, Form, Alert, Button } from "antd";
import ImageProviderAndUpload from "../../components/utils/ImageProviderAndUpload";
import { appDimensions } from "../../components/utils/theme";
import { useImageAttachmentId } from "../../hooks/useImageAttachmentId";

export interface IReviewFormInputBase {
  rating: number | undefined;
  review: string;
  attachmentImageId: string | null;
}

export interface IReviewFormProps {
  loading?: boolean;
  values?: IReviewFormInputBase;
  error?: string | null;
  onSubmit: (values: IReviewFormInputBase) => void;
}

const newInitialValues = (attachmentId: string) => {
  const initialValues: IReviewFormInputBase = {
    rating: undefined,
    review: "",
    attachmentImageId: attachmentId,
  };

  return initialValues;
};

const validationSchema = yup.object().shape({
  rating: yup.number().required(appMessages.requiredField),
  review: yup.string(),
});

const classes = {
  section: css({
    maxWidth: "500px",
    margin: "auto",
  }),
};

const ReviewForm: React.FC<IReviewFormProps> = (props) => {
  const { loading, error, onSubmit } = props;
  const { attachmentId, consumeAttachmentId, setUploadCount } =
    useImageAttachmentId({
      isGroupImage: true,
    });

  const wrappedOnSubmit = React.useCallback(
    async (
      args: IReviewFormInputBase,
      helpers: FormikHelpers<IReviewFormInputBase>
    ) => {
      await onSubmit(args);
      consumeAttachmentId();
      helpers.resetForm();
    },
    [consumeAttachmentId, onSubmit]
  );

  const formik = useFormik({
    validationSchema,
    onSubmit: wrappedOnSubmit,
    initialValues: props.values || newInitialValues(attachmentId),
  });

  const commentNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Review"
      help={
        formik.touched.review && (
          <FormItemError message={formik.errors.review} />
        )
      }
    >
      <Input.TextArea
        value={formik.values.review}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="review"
        placeholder="Your review"
        autoSize={{ minRows: 2 }}
        disabled={loading}
      />
    </Form.Item>
  );

  const ratingNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Rating"
      help={
        formik.touched.rating && (
          <FormItemError message={formik.errors.rating} />
        )
      }
    >
      <Rate
        value={formik.values.rating}
        disabled={loading}
        onChange={(value) => formik.setFieldValue("rating", value)}
      />
    </Form.Item>
  );

  const attachmentNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Attachment"
    >
      <ImageProviderAndUpload
        isGroupImages
        allowDelete
        id={attachmentId}
        imageAltBase="Review attachment"
        height={appDimensions.imageWidth}
        width={appDimensions.imageWidth}
        onCountChange={setUploadCount}
      />
    </Form.Item>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.section}>
        {error && (
          <Form.Item>
            <Alert type="error" message={error} />
          </Form.Item>
        )}
        {ratingNode}
        {commentNode}
        {attachmentNode}
      </div>
      <div className={classes.section}>
        <Form.Item style={{ marginTop: "32px" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Submitting Review..." : "Submit Review"}
          </Button>
        </Form.Item>
      </div>
    </form>
  );
};

export default ReviewForm;
