import React from "react";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { css } from "@emotion/css";
import { appPaths } from "../../variables";

const initialValues = {
  trackingId: "",
};

const messages = {
  trackingIdRequired: "Please enter a tracking ID",
};

const validationSchema = yup.object().shape({
  trackingId: yup.string().required(messages.trackingIdRequired),
});

const classes = {
  error: css({
    color: "#ff4d4f",
  }),
};

const Tracking = (props) => {
  const { initialTrackingId, loading } = props;
  const formik = useFormik({
    validationSchema,
    initialValues: { ...initialValues, trackingId: initialTrackingId },
    onSubmit: (data) => {
      window.location.href = `${appPaths.tracking}/${data.trackingId}`;
    },
  });

  return (
    <section className="domain_search_area sec_pad">
      <div className="map_bg"></div>
      <div className="container">
        <div className="tracking_box_info">
          <h3 className="wow fadeInUp" data-wow-delay="0.3s">
            Track your parcels from pick up to drop off
          </h3>

          <form
            className="row seo_subscribe_form form-inline justify-content-center"
            onSubmit={formik.handleSubmit}
            style={{ flexDirection: "column" }}
          >
            <div className="input-group domain_form_inner">
              <input
                id="trackingId"
                name="trackingId"
                type="text"
                className="form-control rounded"
                placeholder="Enter Tracking ID"
                aria-label="Search"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.trackingId}
                disabled={loading}
              />
              <button
                type="submit"
                className="btn download_btn movebot-icon-btn"
                disabled={loading}
              >
                {loading && <LoadingOutlined />}
                Track
              </button>
            </div>
            {formik.touched.trackingId && formik.errors.trackingId ? (
              <div className={classes.error}>{formik.errors.trackingId}</div>
            ) : null}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Tracking;
