import React from "react";

const TermsOfServiceItemEight = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
       
                The Provider is solely liable for the ordered Services and any
                defects or issues related to the Services and other shortcomings
                in the performance of the User Agreements and Movebot does not
                assume any liability thereof.
         
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
        
                The Movebot Platform is provided to the User strictly on an “as
                is” and “as available” basis. Movebot will not be liable for any
                interruptions, connection errors, unavailability of, or faults
                in the Movebot Platform. Movebot is not providing any
                warranties, guarantees or representations regarding the
                features, functions and quality of the Movebot Platform,
                including regarding the absence of apparent or hidden defects,
                fitness for ordinary or particular (special) purpose.
            
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
              
                Neither Movebot nor the Client is liable to the respective other
                party, whether under the General Terms or otherwise, for any
                damages other than direct proprietary damages. A party is not
                liable for any loss of revenue, loss of business, anticipated
                savings or profits or any indirect, special or consequential
                damages or similar damages.
              
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemEight;
