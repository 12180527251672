import React from "react";

const TermsOfServiceItemSix = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
            
                1. Movebot is entitled to remove any User from the Movebot
                Platform with immediate effect and/or refuse or cancel any
                Orders, if the Client causes any abuse or harm to the Movebot
                Platform, if Movebot has reasonable belief of fraudulent acts by
                the Client when using the Movebot Platform, or if the Client
                otherwise fails to comply with his/her obligations under these
                General Terms
          
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemSix;
