import React from 'react';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner/Banner';
import Tracking from "./../components/Tracking/Tracking";
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';
import MoveBotService from './../components/movebotService/MoveBotService';
import Features from '../components/MoveBotFeatutres/Features';
import FeaturesTwo from './../components/MoveBotFeatutres/FeaturesTwo';
import Action from './../components/Action/Action';
import DownloadApp from '../components/DownloadApp/DownloadApp';

const Home = () => {
    return (
      <>
        <div className="body_wrapper">
          <Navbar mClass="menu_four" nClass="w_menu" />
          <Banner />
          <Tracking />
          <MoveBotService />
          <Features />
          <Action
            aClass="h_action_area"
            aimage="action_img.png"
            colClass="col-lg-6"
            colClassTwo="col-lg-6"
            Atitle="Deliver unmatched services to your existing clients and meet new ones."
            Atext="Our tracking and delivery management software is trusted by courier companies/drivers and their clients."
          />
          <FeaturesTwo />
          <DownloadApp />
          <PrototypeFooter
            rclass={"payment_footer_area_two"}
            FooterData={FooterData}
          />
        </div>
      </>
    );
}
export default Home;
