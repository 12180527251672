import get from "lodash/get";

// For casting types
export  function cast<ToType>(resource: any): ToType {
  return resource as unknown as ToType;
}
 
// For indexing arrays by a field or the array elements
function defaultIndexer(data: any, path: any) {
  if (path) {
    return get(data, path);
  }

  return data;
}

function defaultReducer(data: any) {
  return data;
}

export type ArrayItemIndex = string;
export type ArrayItemIndexer<T> = (
  current: T,
  path: (T extends object ? keyof T : never) | undefined,
  arr: T[],
  index: number
) => ArrayItemIndex | undefined;

export type ArrayItemReducer<T, R> = (current: T, arr: T[], index: number) => R;

export interface IIndexArrayOptions<T, R> {
  path?: T extends object ? keyof T : never;
  indexer?: ArrayItemIndexer<T>;
  reducer?: ArrayItemReducer<T, R>;
}

export function indexArray<T, R = T>(
  arr: T[] = [],
  opts: IIndexArrayOptions<T, R> = {}
): Record<string, R> {
  const indexer = opts.indexer || defaultIndexer;
  const path = opts.path;
  const reducer = opts.reducer || defaultReducer;

  const result = arr.reduce((accumulator, current, index) => {
    const key = indexer(current, path, arr, index);

    if (!key) {
      return accumulator;
    }

    accumulator[key] = reducer(current, arr, index);
    return accumulator;
  }, {} as Record<string, R>);

  return result;
}
