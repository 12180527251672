import React from "react";
import { Avatar, Space, Typography } from "antd";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { ShopOutlined } from "@ant-design/icons";

export interface IReviewOrganizationSectionProps {
  organizationId: string;
  organizationName: string;
}

const ReviewOrganizationSection: React.FC<IReviewOrganizationSectionProps> = (
  props
) => {
  const { organizationId, organizationName } = props;

  return (
    <Space direction="vertical">
      <Typography.Title level={5}>Orgnanization</Typography.Title>
      <Space size="large" align="center">
        <Avatar
          alt="Profile picture of the organization that delivered the order in review."
          shape="circle"
          src={getLoadSingleImagePath(organizationId)}
          size={50}
          icon={<ShopOutlined />}
        />
        <Typography.Text>{organizationName}</Typography.Text>
      </Space>
    </Space>
  );
};

export default ReviewOrganizationSection;
