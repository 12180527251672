import { Image, Space, Typography } from "antd";
import { css } from "@emotion/css";
import React from "react";
import { appColors, appDataImages } from "./theme";
import ImageWithFallback, {
  IImageWithFallbackProps,
} from "./ImageWithFallback";

export interface IImagesWithPreviewProps {
  width?: number;
  height?: number;
  images: Array<IImageWithFallbackProps>;
}

const classes = {
  viewMore: css({
    color: appColors.primaryColor + " !important",
    display: "inline-block",
    marginTop: "12px",
    cursor: "pointer",
    textDecoration: "underline",
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
};

const ImagesWithPreview: React.FC<IImagesWithPreviewProps> = (props) => {
  const { width, height, images } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <div className={classes.root}>
      <Space
        wrap
        size={["middle", "middle"]}
        className={css({
          "& .ant-skeleton-image": { width, height, borderRadius: "4px" },
        })}
      >
        {images.map((image, i) => {
          return (
            <ImageWithFallback
              {...image}
              key={image.src + i}
              preview={false}
              width={width}
              height={height}
              src={image?.src || appDataImages.brokenImage}
              onClick={() => setVisible(true)}
            />
          );
        })}
      </Space>
      {images.length > 1 && (
        <Typography.Text
          className={classes.viewMore}
          onClick={() => setVisible(true)}
        >
          Preview images
        </Typography.Text>
      )}
      <div style={{ display: "none" }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: setVisible }}>
          {images.map((image, i) => {
            return (
              <Image
                {...image}
                key={image.src + i}
                fallback={image.fallbackSrc || appDataImages.brokenImage}
              />
            );
          })}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default ImagesWithPreview;
