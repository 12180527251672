import React from 'react';
import Reveal from "react-reveal";

const Serviceitem = (props) => {

  let { sTitle, pText, icon, itemClass } = props;
  return (
    <div className="col-lg-4 col-sm-6">
      <Reveal bottom cascade>
        <div className={`erp_service_item ${itemClass}`}>
          <img src={require("../../img/erp-home/" + icon)} alt="" />
          <h3 className="h_head">{sTitle}</h3>
          <p>{pText}</p>
        </div>
      </Reveal>
    </div>
  );
}

export default Serviceitem;
