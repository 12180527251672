import { appPaths } from "../../variables";

const FooterData = {
  socialLinks: [
    {
      id: 1,
      url: "https://web.facebook.com/Movebotng",
      icon: "ti-facebook",
    },
    // {
    //   id: 2,
    //   url: "https://twitter.com/",
    //   icon: "ti-twitter-alt",
    // },
    {
      id: 3,
      url: "https://www.instagram.com/movebotng/",
      icon: "ti-instagram",
    },
  ],

  aboutLinks: [
    {
      id: 1,
      url: appPaths.about,
      item: "About Us",
    },
    {
      id: 2,
      url: appPaths.terms,
      item: "Terms of Service",
    },
    {
      id: 3,
      url: appPaths.policy,
      item: "Privacy Policy",
    },
  ],
  helpLinks: [
    {
      id: 1,
      url: "",
      item: "Contact Info",
      item2: "Phone: +234-7069992100, Email: support@movebot.ng",
    },
    {
      id: 2,
      url: "#",
      item: "",
      item3: "",
    },
  ],
};
export default FooterData;
