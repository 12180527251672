import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import ImageAPI, { getLoadGroupImagePath } from "../../api/endpoints/image";
import { getEndpointResultMessage } from "../../api/invokeREST";
import AlertWithReload from "./AlertWithReload";
import ImageSkeleton from "./ImageSkeleton";
import ImagesWithPreview from "./ImagesWithPreview";
import { IImageWithFallbackProps } from "./ImageWithFallback";

export interface IImageProviderProps {
  width?: number;
  height?: number;
  allowDelete?: boolean;
  count?: number;
  id: string;
  imageAltBase: string;
  onCompleteDelete?: (index: number, remaining: number) => void;
  onCountChange?: (count: number) => void;
  render?: (
    node: React.ReactNode,
    props: { images: IImageWithFallbackProps[] }
  ) => React.ReactElement;
}

export interface IImageProviderRefMethods {
  reload: () => void;
}

const ImageProvider: React.ForwardRefRenderFunction<
  IImageProviderRefMethods,
  IImageProviderProps
> = (props, ref) => {
  const {
    id,
    count,
    imageAltBase,
    width,
    height,
    allowDelete,
    onCompleteDelete,
    onCountChange,
    render,
  } = props;

  const getFilenames = React.useCallback(async () => {
    if (id) {
      return ImageAPI.getFilenames({ id });
    }

    return [];
  }, [id]);

  const {
    loading,
    error,
    data: filenames,
    run: reloadFilenames,
  } = useRequest(getFilenames);

  React.useImperativeHandle(ref, () => {
    return {
      reload: reloadFilenames,
    };
  });

  const onDelete = React.useCallback(
    async (filename: string, index: number) => {
      if (filenames) {
        const result = await ImageAPI.deleteImages({ id, filename });
        message.success(getEndpointResultMessage(result) || "Image deleted");

        if (onCompleteDelete) {
          setTimeout(() => {
            onCompleteDelete(index, filenames?.length - 1);
          }, 1000);
        }
      }
    },
    [id, filenames, onCompleteDelete]
  );

  const images = React.useMemo(() => {
    return filenames?.map((filename, index) => {
      const imageProps: IImageWithFallbackProps = {
        allowDelete,
        src: getLoadGroupImagePath(filename),
        alt: `${imageAltBase} image ${index + 1}`,
        onDelete: allowDelete ? () => onDelete(filename, index) : undefined,
      };

      return imageProps;
    });
  }, [filenames, allowDelete, imageAltBase, onDelete]);

  React.useEffect(() => {
    if (onCountChange) {
      onCountChange(images?.length || 0);
    }
  }, [images, onCountChange]);

  if (loading) {
    return <ImageSkeleton count={count} width={width} height={height} />;
  } else if (error) {
    return (
      <AlertWithReload
        message="Error loading image data"
        reloadFn={reloadFilenames}
      />
    );
  }

  if (!images || images.length === 0) {
    return null;
  }

  const node = (
    <ImagesWithPreview width={width} height={height} images={images} />
  );

  if (render) {
    return render(node, { images });
  } else {
    return node;
  }
};

export default React.forwardRef(ImageProvider);
