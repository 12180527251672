import React from 'react';
import Sectitle from "../Title/Sectitle";
import Serviceitem from "./Serviceitem";

const MoveBotService = () => {
    return (
      <section className="erp_service_area sec_pad">
        <div className="container">
          <Sectitle
            sClass="hosting_title erp_title text-center"
            Title="What We Do"
            TitleP="Movebot is a parcel/cargo delivery and tracking management solution developed to empower courier service providers and their clients."
          />
          <div className="row">
            <Serviceitem
              itemClass="pr_70"
              sTitle="Delivery management system"
              pText="We provide end-to-end delivery technologies that empower couriers and their drivers to receive delivery requests, process the requests, and keep transaction records."
              icon="icon12.png"
            />
            <Serviceitem
              itemClass="pr_70 pl_10"
              sTitle="Dispatch management system"
              pText="Empower your drivers/riders, collaborate with reliable couriers using Movebot, and send your parcel/cargo without hassles 24/7."
              icon="erp_icon2.png"
            />
            <Serviceitem
              itemClass="pl_70"
              sTitle="Delivery Tracking system"
              pText="Our solution allows you to see how your parcel/cargo moves from pick-up point to drop-off point. No more anxiety over parcels/cargo in transit."
              icon="icon13.png"
            />
          </div>
        </div>
      </section>
    );
}

export default MoveBotService;
