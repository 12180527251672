import React from "react";
import { Rate, Space } from "antd";
import LabeledNode from "../../components/utils/LabeledNode";
import { IReviewInfo } from "../../global/review";

export interface ISubmittedReviewProps {
  reviewInfo: IReviewInfo;
}

const SubmittedReview: React.FC<ISubmittedReviewProps> = (props) => {
  const { reviewInfo } = props;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <LabeledNode
        nodeIsText
        direction="vertical"
        label="Review"
        node={reviewInfo.Review}
        style={{ width: "100%" }}
        spaceSize={0}
      />
      <LabeledNode
        direction="vertical"
        label="Rating"
        node={<Rate disabled value={reviewInfo.Rating} />}
        style={{ width: "100%" }}
        spaceSize={0}
      />
      {/* <ImageProvider
        id={getReviewAttachmentId(reviewInfo)}
        imageAltBase={`${reviewInfo.OrderItemName} review attachment`}
        height={appDimensions.imageWidth}
        width={appDimensions.imageWidth}
      /> */}
    </Space>
  );
};

export default SubmittedReview;
