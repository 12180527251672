import React from "react";

const TermsOfServiceItemFive = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
       
                If the Client has any complaints regarding the Services, the
                Client is encouraged to contact the Service Provider directly
                using the contact information made available on the Platform.
      
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.7s">
    
                However, the Client may also inform Movebot thereof through the
                Movebot Platform as soon as possible, but no later than within
                one month of the provision of respective Service. In such cases,
                Movebot may request evidence or explanation of the circumstances
                related to the complaint. Although Movebot is not the provider
                of Services and is not responsible for respective Services,
                Movebot may try to facilitate an amicable solution between the
                Service Provider and the Client.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemFive;
