import React from 'react';
import Stserviceitems from "./StserviceItem";
import Reveal from "react-reveal/Reveal";

const Sservice = () => {
  return (
    <section className="agency_service_area bg_color">
      <div className="container">
        <h2
          className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
          data-wow-delay="0.2s"
        >
          Our solutions
        </h2>
        <div className="row mb_30">
          <div className="col-lg-4 col-sm-6">
            <Reveal effect="fadeInUp" duration={1100}>
              <Stserviceitems
                iShap="icon12.png"
                Sicon="ti-panel"
                text="Delivery management system"
                description="We provide end-to-end delivery technologies that empower couriers and their drivers to receive delivery requests, process the requests, and keep transaction records."
              />
            </Reveal>
          </div>
          <div className="col-lg-4 col-sm-6">
            <Reveal effect="fadeInUp" duration={1400}>
              <Stserviceitems
                iShap="icon13.png"
                Sicon="ti-layout-grid2"
                text="Dispatch management system"
                description="Empower your drivers/riders, collaborate with reliable couriers using Movebot and send your parcel/cargo without hassles 24/7."
              />
            </Reveal>
          </div>
          <div className="col-lg-4 col-sm-6">
            <Reveal effect="fadeInUp" duration={1700}>
              <Stserviceitems
                iShap="erp_icon2.png"
                Sicon="ti-gallery"
                text="Delivery Tracking system"
                description="Our solution allows you to see how your parcel/cargo moves from pick-up point to drop-off point. No more anxiety over parcels/cargo in transit."
              />
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sservice;
