import React from "react";
import styled from "styled-components";

const TrackingDetails = ({ results }) => {
  return (
    <>
      <Status>
        <h3>{results.CurrentPackageStatus}</h3>
      </Status>
      {results.Events.map((result, i) => (
        <React.Fragment key={i}>
          <Response>
            <Date>{result.EventDate_}</Date>
            <Info>{result.Description}</Info>
          </Response>
        </React.Fragment>
      ))}
    </>
  );
};

export default TrackingDetails;

const Response = styled.div`
  border-radius: 7px;
  position: relative;
  min-height: 80px;
  padding: 30px 5px 20px 5px;
  width: 50%;
  margin: 10px auto 20px auto;
  display: flex;
  justify-content: ${({ errStyle }) => (errStyle ? "center" : "center")};
  align-items: center;
  gap: 5px;
  background: ${({ errStyle }) => (errStyle ? "" : "#fff")};
  border: ${({ errStyle }) =>
    errStyle ? "2px solid #0f7173" : "1px solid #0f7173"};
  color: ${({ errStyle }) => (errStyle ? "#0F7173" : "#262626")};
  text-align: left;
  p {
    padding: 1rem;
  }
  ::before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 0.3rem;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    margin: 16px;
    width: calc(100% - 32px);
  }
`;

const Date = styled.div`
  padding: 1rem 2rem;
  @media (max-width: 768px) {
    padding: 0.6rem 1.8rem 0;
    width: 100%;
  }
  color: #0066ff;
  font-size: 16px;
  margin-left: 0px;
  width: 40%;
`;
const Info = styled.div`
  padding: 1rem 2rem;
  @media (max-width: 768px) {
    padding: 0.6rem 1.8rem 0;
    width: 100%;
  }
  font-size: 15px;
  color: #262626;
  width: 60%;
`;
const Status = styled.div`
  width: 100%;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f7173;
`;
