import React, { useState, useEffect } from "react";
import axios from "axios";
import Tracking from "./Tracking";
import TrackingDetails from "./TrackingDetails";
import CustomNavbar from "../Navbar";
import PrototypeFooter from "./../Footer/PrototypeFooter";
import FooterData from "../Footer/FooterData";
import appVariables from "../../variables";
import { Alert, message } from "antd";
import { appMessages } from "../../global/messages";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { css,  } from "@emotion/css";

const classes = {
  error: css({
    display: "flex",
    justifyContent: "center",

    "@media (min-width: 1148px)": {
      margin: "120px 0px !important",
    },

    "@media (max-width: 767px)": {
      margin: "16px !important",
    },
  }),
};

const TrackingPage = (props) => {
  const [fetchResult, setFetchResult] = useState();
  const [fetchErr, setFetchErr] = useState("");
  const [fetching, setFetching] = useState(true);
  const IdParam = props.match.params.idDetails;

  useEffect(() => {
    setFetching(true);
    axios
      .get(
        `${appVariables.serverURL}/LandingPage/Tracker?trackingId=${IdParam}`
      )
      .then((res) => {
        setFetchResult(res.data);
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = getEndpointResultMessage(error.response.data);
          message.error(errorMessage, appVariables.messageDuration);
          setFetchErr(errorMessage);
        } else {
          message.error(appMessages.requestError, appVariables.messageDuration);
          setFetchErr(appMessages.requestError);
        }
      })
      .finally(() => {
        setFetching(false);
      });
  }, [IdParam]);

  return (
    <>
      <CustomNavbar />
      <Tracking initialTrackingId={IdParam} loading={fetching} />
      {fetchErr && (
        <div className={classes.error}>
          <Alert message={fetchErr} type="error" className="container" />
        </div>
      )}
      {fetchResult && <TrackingDetails results={fetchResult} />}
      <PrototypeFooter FooterData={FooterData} />
    </>
  );
};

export default TrackingPage;
