import React from 'react';
import CustomNavbar from "../components/Navbar";
import PrototypeFooter from "../components/Footer/PrototypeFooter";
import FooterData from "../components/Footer/FooterData";
import Breadcrumb from "../components/Breadcrumb";
import TermsOfService from './../components/TermsOfService/TermsOfService';

const TermsOfServicePage = () => {
  return (
    <>
      <CustomNavbar />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Terms of Service"
      />
      <section className="event_schedule_area sec_pad">
        <div className="container">
          <div className="hosting_title security_title text-center">
            <h2>Terms of Service</h2>
            <p>
              These General Terms shall apply to all relations between Movebot
              and the User related to the use of the cargo/parcel delivery and
              tracking Platform (as defined below) as well as certain aspects of
              User Agreements concluded through the Movebot Platform (as defined
              below). By signing up in the Movebot Platform, the User agrees to
              the application and content of these General Terms.
            </p>
          </div>
          <div className="event_schedule_inner">
            <div className="tab-content event_tab_content">
              <div
                className="tab-pane fade show active"
                id="one"
                role="tabpanel"
                aria-labelledby="one-tab"
              >
                <div className="media">
                  <div className="media-left">Additional Terms</div>
                  <div className="media-body">
                    <p>
                      This means any additional terms and conditions applicable
                      to a specific service as made available through the
                      Movebot Platform, or on the site of providing the Service
                      by the Provider.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Movebot</div>
                  <div className="media-body">
                    <p>
                      means a product of E Techwin Integrated Limited, registry
                      code 12801774, address 18, Olumo Street, Onike, Lagos,
                      Nigeria.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Movebot platform</div>
                  <div className="media-body">
                    <p >
                      means the mobile application and related platform,
                      including webpage at https://movebot.ng, operated by
                      Movebot as Software as a service and virtual marketplace
                      that enables the participating Providers to provide
                      information about their available Services to the Users so
                      that the Users can book and track the Services without
                      participating in how User Agree with the Providers and pay
                      for Services
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Client</div>
                  <div className="media-body">
                    <p>
                      means any User of the Movebot Platform that has placed an
                      Order and enters into a User Agreement with the Provider
                      through the Movebot Platform.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">General Terms</div>
                  <div className="media-body">
                    <p>
                      means these terms and conditions applicable to the
                      relationship between Movebot and the Client in relation to
                      the use of the Movebot Platform by the Client.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Order</div>
                  <div className="media-body">
                    <p>
                      means the order that the Client has placed with the
                      Provider through Movebot Platform to book and use the
                      service.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Provider</div>
                  <div className="media-body">
                    <p>
                      means an entity that offers its Services to Clients
                      through the Movebot Platform.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Service</div>
                  <div className="media-body">
                    <p>
                      means the service offered by the provider through Movebot
                      Platform, including parcel/cargo ordering, tracking, and
                      drivers management.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">Service Fee</div>
                  <div className="media-body">
                    <p>
                      means the price and/or fee (together with applicable
                      value-added, sales, or other taxes where applicable) that
                      the Client has to pay to the Provider for the ordered
                      Service.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">User Agreement</div>
                  <div className="media-body">
                    <p>
                      means the agreement concluded through the Movebot Platform
                      between the Client and the Provider for the provision of
                      the Service ordered by the Client on the specific terms
                      and conditions applicable to such Service as made
                      available through the Movebot Platform.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">User</div>
                  <div className="media-body">
                    <p>
                      means any person that has registered a user account on the
                      Movebot Platform and uses the services of the Movebot
                      Platform through that user account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TermsOfService />
      <PrototypeFooter FooterData={FooterData} />
    </>
  );
}
export default TermsOfServicePage;
