import React from 'react';
import CustomNavbar from '../components/Navbar';
import Breadcrumb from '../components/Breadcrumb';
import Sservice from '../components/Service/Sservice/Sservice';
import FooterData from '../components/Footer/FooterData';
import PrototypeFooter from './../components/Footer/PrototypeFooter';
import Sectitle from "../components/Title/Sectitle";
import Address from './../components/Address';

const About = () => {
    return (
      <div className="body_wrapper">
        <CustomNavbar
          slogo="sticky_logo"
          mClass="menu_four"
          nClass="w_menu ml-auto mr-auto"
        />
        <Breadcrumb
          breadcrumbClass="breadcrumb_area"
          imgName="breadcrumb/banner_bg.png"
          Ptitle="About Us"
        />
        <div style={{ marginTop: "50px" }}>
          <Sectitle
            sClass="hosting_title erp_title text-center"
            Title="What We Do"
            TitleP="Movebot is a parcel/cargo delivery and tracking management solution developed to empower courier service providers and their clients."
          />
        </div>
        <Sectitle
          sClass="hosting_title erp_title text-center"
          Title="Our Vision"
          TitleP="To help couriers and their clients deliver all parcels/cargo fast and easy, track items, reduce resources of money, time, and man power used during deliveries."
        />
        <Sservice />
        <div style={{ marginTop: "5px" }}>
          <Sectitle
            sClass="hosting_title erp_title text-center"
            Title="Contact Us"
            TitleP="Reach out to us. We are ready to serve you"
          />
        </div>
        <Address />

        <PrototypeFooter FooterData={FooterData} />
      </div>
    );
}
export default About;
