import React, { useState } from "react";
import Reveal from "react-reveal";
import AndroidIcon from "../../img/download/google-icon.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Phone from "../../img/download/iPhoneX2.png";
import Modal from "../PopupModal/ModalScreen";

const DownloadApp = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <section className="get_started_area">
        <div className="shap one"></div>
        <div className="shap two"></div>
        <div className="shap one three"></div>
        <div className="shap two four"></div>
        <div className="container">
          <div className="row">
            {modalOpen && <Modal setOpenModal={setModalOpen} />}
            <div className="col-lg-6 d-flex align-items-center">
              <Reveal bottom cascade>
                <div className="get_content">
                  <h3
                    className="f_400 f_p wow fadeInLeft"
                    data-wow-delay="0.2s"
                  >
                    MoveBot Apps
                  </h3>

                  <h2
                    className="f_700 f_p f_size_40 l_height50 mb_20 wow fadeInLeft"
                    data-wow-delay="0.3s"
                  >
                    Click to download MoveBot driver's app
                  </h2> 
                  <Image>
                    <a href="https://play.google.com/store/apps/details?id=com.driver.movebot.ng.app&hl=en_NZ&gl=US" target="_blank" rel="noopener noreferrer">
                      <img
                        style={{
                          height: "50px",
                          width: "170px",
                          margin: "0px 10px 0px 20px",
                        }}
                        src={AndroidIcon}
                        alt="Download Movebot Driver's Android app"
                      />
                    </a>
                  </Image>
                 
                </div>
              </Reveal>
            </div>
            <div className="col-lg-6 text-right">
              <Reveal effect="fadeInRight" duration={2200}>
                {" "}
                <img src={Phone} alt="" />
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Image = styled("div")`
  width: 10rem;

  &:hover {
    transform: scale(0.97);
    transition: all 0.5s ease;
  }
  img {
  }
`;

export default DownloadApp;
