import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "./assets/themify-icon/themify-icons.css";
import "./assets/simple-line-icon/simple-line-icons.css";
import "./assets/font-awesome/css/all.css";
import "./assets/elagent/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.css";
import "./assets/responsive.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js";




// const loader = document.querySelector("#preloader");

// // if you want to show the loader when React loads data again
// const showLoader = () => loader.classList.remove("loader--hide");

// const hideLoader = () => loader.classList.add("loader--hide");

// setTimeout(
//   () =>
    // the show/hide functions are passed as props
    ReactDOM.render(
      // hideLoader={hideLoader} showLoader={showLoader}
      <App />,
      document.getElementById("root"))
    // ),
  // 1000
// );


