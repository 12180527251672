import React from "react";

const TermsOfServiceItemTwo = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
              
                Movebot shall make reasonable efforts to make the Services
                available 24 hours a day, seven days a week, but does not
                warrant that the access will be uninterrupted.
          
               Access may be interrupted, among others, for scheduled and
              unscheduled maintenance.
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.7s">
             
                Movebot may change or remove different parts of the Movebot
                Platform, change its features and add or remove the Providers
                participating in the Movebot Platform in part or in whole at any
                time without prior notice.
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.9s">
           
                The Platform may include links to other websites or services or
                third-party content. Movebot does not endorse any such linked
                sites or third-party content, or the information, material,
                products, or services contained on or accessible through linked
                sites.
          
              Access and use of linked sites, including the information,
              material, products, and services on linked sites or available
              through linked sites is solely at the Client’s own risk.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemTwo;
