import React from 'react';
import Reveal from "react-reveal";

const FeaturesItem = (props) => {
    let {
      rowClass,
      hImage,
      hTitle,
      hPragraph,
      icon,
      icon2,
      icon3,

      text,
      text2,
      text3,
      tdescription,
      tdescription2,
      tdescription3,
    } = props;
  return (
    <div className={`row ${rowClass}`}>
      <div className="col-lg-6">
        <Reveal bottom cascade>
          <div className="h_features_img">
            <img src={require("../../img/hosting/" + hImage)} alt="" />
          </div>
        </Reveal>
      </div>
      <div className="col-lg-6">
        <Reveal right cascade>
          <div className="h_features_content">
            <div className="hosting_title">
              <h2>{hTitle}</h2>
              <p>{hPragraph}</p>
            </div>
            <div className="media h_features_item">
              <img src={require("../../img/hosting/" + icon)} alt="" />
              <div className="media-body">
                <h4 className="h_head">{text}</h4>
                <p>{tdescription}</p>
              </div>
            </div>
            <div className="media h_features_item">
              <img src={require("../../img/hosting/" + icon2)} alt="" />
              <div className="media-body">
                <h4 className="h_head">{text2}</h4>
                <p>{tdescription2}</p>
              </div>
            </div>
            <div className="media h_features_item">
              <img src={require("../../img/hosting/" + icon3)} alt="" />
              <div className="media-body">
                <h4 className="h_head">{text3}</h4>
                <p>{tdescription3}</p>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
}

export default FeaturesItem;
