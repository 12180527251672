import Breadcrumb from "../../components/Breadcrumb";
import FooterData from "../../components/Footer/FooterData";
import PrototypeFooter from "../../components/Footer/PrototypeFooter";
import CustomNavbar from "../../components/Navbar";
import Partner from './../../components/Partner/Partner';

const PartnerForm = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-02.jpg"
        Ptitle="Signup for Trial"
        Pdescription="Complete and submit this form.
We will create a Movebot trial account for you to get started."
      />
      <Partner />
      <PrototypeFooter FooterData={FooterData} />
    </div>
  );
};
export default PartnerForm;
