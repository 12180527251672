
import React from 'react';
import TermsOfServiceItemOne from "./TermsOfServiceItemOne";
import TermsOfServiceItemTwo from './TermsOfServiceItemTwo';
import TermsOfServiceItemThree from './TermsOfServiceItemThree';
import TermsOfServiceItemFour from "./TermsOfServiceItemFour";
import TermsOfServiceItemFive from "./TermsOfServiceItemFive"
import TermsOfServiceItemSix from "./TermsOfServiceItemSix";
import TermsOfServiceItemSeven from "./TermsOfServiceItemSeven";
import TermsOfServiceItemEight from "./TermsOfServiceItemEight";
import TermsOfServiceItemNine from "./TermsOfServiceItemNine";

const TermsOfService = () => {
  return (
    <>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemOne Title="LEGAL FRAMEWORK " />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemTwo Title="MOVEBOT PLATFORM " />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemThree Title="USER ACCOUNTS " />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemFour Title="ORDERING SERVICES, FEES, AND PAYMENTS" />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemFive Title="COMPLAINTS" />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemSix Title="CANCELLATION AND SUSPENSION OF USE" />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemSeven Title="INTELLECTUAL PROPERTY RIGHTS AND DATA PROCESSING" />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemEight Title="LIABILITY" />
        </div>
      </section>
      <section className="h_security_about_area">
        <div className="container">
          <TermsOfServiceItemNine Title="MISCELLANEOUS" />
        </div>
      </section>
    </>
  );
}
export default TermsOfService;
