import React from "react";

const Address = () => {
  return (
    <section className="contact_info_area sec_pad bg_color">
      <div className="container">
        <div className="row">
          <div className="contact_form col-lg-5">
            <div className="contact_info_item">
              <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                Office Address
              </h6>
              <p className="f_400 f_size_15">
                {" "}
                18, Olumo Street, Unilag Second Gate Road, Onike, Lagos, Nigeria
              </p>
            </div>
          </div>
          <div className="contact_info_item">
            <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contact Info</h6>
            <p className="f_400 f_size_15">
              <span className="f_400 t_color3">Phone:</span>{" "}
              <a href="tel:2347069992100">+234-7069992100</a>
            </p>
            <p className="f_400 f_size_15">
              <span className="f_400 t_color3">Email:</span>{" "}
              <a href="mailto:saasland@gmail.com">support@movebot.ng</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Address;
