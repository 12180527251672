import React from "react";
import { Button, Divider, Result, Space } from "antd";
import axios from "axios";
import ReviewForm, { IReviewFormInputBase } from "./ReviewForm";
import { useRequest } from "ahooks";
import CustomNavbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import FooterData from "../../components/Footer/FooterData";
import PrototypeFooter from "../../components/Footer/PrototypeFooter";
import appVariables from "../../variables";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import { IReviewInfo } from "../../global/review";
import PageLoading from "../../components/utils/PageLoading";
import ReviewOrganizationSection from "./ReviewOrganizationSection";
import ReviewOrderItemSection from "./ReviewOrderItemSection";

// @ts-ignore
import URLSearchParams from "core-js/web/url-search-params";
import { isFunction } from "formik";
import SubmittedReview from "./SubmittedReview";
import { getEndpointResultMessage } from "../../api/invokeREST";

const classes = {
  main: css({
    margin: "24px auto",
    marginTop: "32px",
    maxWidth: "500px",
    padding: "0px 16px",
  }),
  gotoHomepageBtn: css({
    color: "#f57f55 !important",
    textDecoration: "underline",
  }),
};

const messages = {
  errorFetchingReview: "Error fetching review info.",
};

export interface IReviewFormInput extends IReviewFormInputBase {
  reviewId: string;
  reviewForSender: boolean;
}

const ReviewFormContainer: React.FC<{}> = (props) => {
  const [reviewSentMessage, setReviewSentMessage] = React.useState<
    string | null
  >(null);

  const query = window.location.search;
  const queryParams = new URLSearchParams(query);
  const reviewType = queryParams.get("type");
  const reviewId = queryParams.get("id");

  const getReviewInfo = React.useCallback(async () => {
    if (!reviewType || !reviewId) {
      return;
    }

    const params = new URLSearchParams();
    params.set("id", reviewId);
    params.set("type", reviewType);
    const url = `${
      appVariables.serverURL
    }/LandingPage/GetReviewInfo?${params.toString()}`;
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error(
        getEndpointResultMessage(response.data) || messages.errorFetchingReview
      );
    }

    return response.data as IReviewInfo;
  }, [reviewId, reviewType]);

  const getReviewInfoResult = useRequest(getReviewInfo);
  const reviewInfo = getReviewInfoResult.data;
  const onSubmitReview = React.useCallback(
    async (args: IReviewFormInputBase) => {
      if (!reviewInfo) {
        throw new Error("Review info not loaded yet.");
      }

      const url = `${appVariables.serverURL}/LandingPage/PostReview`;
      const data: IReviewFormInput = {
        ...args,
        reviewId: reviewInfo.ReviewId,
        reviewForSender: reviewInfo.ReviewForSender,
      };

      const response = await axios.post(url, data);

      if (response.status !== 200) {
        throw new Error(
          getEndpointResultMessage(response.data) || "Error submitting review."
        );
      }

      await getReviewInfoResult.runAsync();
      setReviewSentMessage(
        getEndpointResultMessage(response.data) || "Review submitted."
      );
    },
    [reviewInfo, getReviewInfoResult]
  );

  const submitReviewResult = useRequest(onSubmitReview, { manual: true });
  let content: React.ReactNode = null;
  const gotoHomepageBtn = (
    <Link to={"/"}>
      <Button
        type="text"
        key="go-to-homepage"
        className={classes.gotoHomepageBtn}
      >
        Go to homepage
      </Button>
    </Link>
  );

  const withReviewInfo = (
    node: React.ReactNode | ((inputReviewInfo: IReviewInfo) => React.ReactNode)
  ) => {
    if (!reviewInfo) {
      return node;
    }

    return (
      <Space direction="vertical" split={<Divider />} style={{ width: "100%" }}>
        <ReviewOrganizationSection
          organizationId={reviewInfo.OrganizationId}
          organizationName={reviewInfo.OrganizationName}
        />
        <ReviewOrderItemSection reviewInfo={reviewInfo} />
        {isFunction(node) ? node(reviewInfo) : node}
      </Space>
    );
  };

  if (!reviewId && !reviewType) {
    content = (
      <Result
        status="error"
        title={
          "Review link is invalid. " +
          "Please ensure you are using the review link sent to your phone number or email address."
        }
        extra={gotoHomepageBtn}
      />
    );
  } else if (getReviewInfoResult.loading) {
    content = <PageLoading messageText="Loading review info..." />;
  } else if (getReviewInfoResult.error) {
    content = (
      <Result
        status="error"
        title={
          getReviewInfoResult.error?.message || messages.errorFetchingReview
        }
        extra={gotoHomepageBtn}
      />
    );
  } else if (getReviewInfoResult.data?.ReviewHasBeenSubmittedAlready) {
    // content = withReviewInfo(
    //   <Result
    //     status="info"
    //     title={"Review has been sent already."}
    //     extra={gotoHomepageBtn}
    //   />
    // );

    content = withReviewInfo((info) => <SubmittedReview reviewInfo={info} />);
  } else if (reviewSentMessage) {
    content = withReviewInfo(
      <Result
        status="success"
        title={reviewSentMessage}
        extra={gotoHomepageBtn}
      />
    );
  } else {
    content = withReviewInfo((info) => (
      <ReviewForm
        loading={submitReviewResult.loading}
        error={submitReviewResult.error?.message}
        onSubmit={submitReviewResult.run}
      />
    ));
  }

  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-02.jpg"
        Ptitle="Review"
        Pdescription="How was your delivery experience?"
      />
      <div className={classes.main}>{content}</div>
      <PrototypeFooter FooterData={FooterData} />
    </div>
  );
};

export default ReviewFormContainer;
