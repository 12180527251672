import React from "react";
import "./Modal.css";
import { Link } from "react-router-dom";
import { appPaths } from "../../variables";

const Modal = ({ setOpenModal }) => {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
          <h4>You beat us to it !</h4>
        </div>
        <div className="body">
          <p>
            Be the first person to know when we are live + free software for a
            specific period"
          </p>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <Link to={appPaths.contact}>
            <button>Contact us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Modal;
