import React from 'react';

const TermsOfServiceItemOne = (props) => {
    let {rClass, Title, description } = props;
    return (
      <div
        className={`row h_analytices_features_item align-items-center ${rClass}`}
      >
        <div className="col-lg-12">
          <div className="h_analytices_content">
            <h2 className="wow fadeInUp" data-wow-delay="0.1s">
              {Title}
            </h2>
            <p className="wow fadeInUp" data-wow-delay="0.3s">
              {description}
            </p>
            <ul className="list-unstyled">
              <li className="wow fadeInUp" data-wow-delay="0.5s">
                The Movebot Platform enables the Users to book and track the
                Services from the providers.
              </li>
              <li className="wow fadeInUp" data-wow-delay="0.7s">
            
                  For the provision of Service through the Movebot Platform, a
                  user agreement is concluded directly between the Client and
                  the Provider
               A user agreement is deemed to be concluded from the moment the
                rider is confirmed on the Movebot Platform.
              </li>
              <li className="wow fadeInUp" data-wow-delay="0.9s">
            
                  By operating the Movebot Platform, Movebot acts only as a
                  provider of the software and is not a party to any User
                  Agreement.
                Movebot is not the provider of the Services and is not liable in
                any way for the performance of the Services.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
}
export default TermsOfServiceItemOne;
