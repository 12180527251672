import React from "react";
import FeaturesItem from "./FeaturesItem";

const FeaturesTwo = () => {
  return (
    <section className="hosting_features_area">
      <div className="container">
        <FeaturesItem
          rowClass="flex-row-reverse"
          hImage="order.png"
          hTitle="Delivery software trusted by merchants and individuals"
          hPragraph="Movebot empowers merchants and individuals in need of reliable, effective, and efficient courier services with 24/7 digital tracking and delivery platforms to reduce the cost and stress of parcel transportation."
          icon2="icon2.png"
          icon="icon3.png"
          icon3="icon4.png"
          icon4="icon5.png"
          text="Deliver more and fast."
          text2="Track all deliveries real-time"
          text3="Control your deliveries."
          text4="Manage dispatch orders/drivers."
          text5="Track items realtime & more."
          tdescription="Send your parcel/cargo through reliable couriers without hassles 24/7."
          tdescription2="See how your parcels/cargo move from pick up point to drop off point. No more anxiety over parcels/cargo in transit."
          tdescription3="Bargain and agree on the delivery terms with couriers of choice save cost, time, and energy spent on parcels/cargo delivery."
          tdescription4="See how your parcel/cargo move from pick up point to drop off point."
        />
      </div>
    </section>
  );
};

export default FeaturesTwo;
