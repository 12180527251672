import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

/*------ Pages-----*/
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import PartnerForm from "./Pages/PartnerForm/PartnerForm";
import TrackingPage from "./components/Tracking/TrackingPage";
import NotFound from "./Pages/404";
import TermsOfServicePage from "./Pages/TermsOfServicePage";
import PrivacyPloicy from "./Pages/PrivacyPolicy";
import ReviewFormContainer from "./Pages/Review/ReviewFormContainer";
import { appPaths } from "./variables";
import "./App.less";

const App = () => {
  return (
    <Router>
      <Switch>
        <ScrollToTopRoute exact path={appPaths.home} component={Home} />
        <ScrollToTopRoute path={appPaths.about} component={About} />
        <ScrollToTopRoute path={appPaths.contact} component={Contact} />
        <ScrollToTopRoute path={appPaths.partner} component={PartnerForm} />
        <ScrollToTopRoute
          path={appPaths.reviews}
          component={ReviewFormContainer}
        />
        <ScrollToTopRoute
          path={appPaths.terms}
          component={TermsOfServicePage}
        />
        <ScrollToTopRoute path={appPaths.policy} component={PrivacyPloicy} />
        <ScrollToTopRoute
          path={`${appPaths.tracking}/:idDetails`}
          component={TrackingPage}
        />
        <ScrollToTopRoute component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
