import React from "react";
import Img from "../../img/hosting/action.png";
import Reveal from "react-reveal";
import { Link } from "react-router-dom";
import { appPaths } from "../../variables";

const Action = (props) => {
  let { aClass, colClass, colClassTwo, Atitle, Atext } = props;
  return (
    <section className={`${aClass}`}>
      <Reveal bottom cascade>
        <div className="container">
          <div className="row align-items-center">
            <div className={`${colClass}`}>
              <div className="h_action_img">
                <img className="img-fluid" src={Img} alt="" />
              </div>
            </div>
            <div className={`${colClassTwo}`}>
              <div className="h_action_content">
                <h2>{Atitle}</h2>
                <p>{Atext}</p>
                <Link to={appPaths.partner} className="download_btn btn_hover">
                  Try for free
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </section>
  );
};

export default Action;
