import React from "react";
import { Link } from "react-router-dom";

const PrototypeFooter = (props) => {
  let FooterData = props.FooterData;
  let { rclass } = props;
  return (
    <footer className={`payment_footer_area ${rclass}`}>
      <div className="footer_top_six">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="f_widget company_widget">
                <a href="index.html" className="f-logo">
                  <img src={require("../../img/MoveBot.png")} alt="logo" />
                </a>
              </div>
              <div>
                <h5 style={{ color: "#fff", marginTop: "20px" }}>
                  Office Address
                </h5>
                <p>
                  18, Olumo Street, Unilag Second Gate Road, Onike, Lagos,
                  Nigeria
                </p>
              </div>

              {/* <div>
                <h5 style={{ color: "#fff" }}>Contact Info</h5>
                <p>Phone: +234-7069992100, Email: support@movebot.ng</p>
              </div> */}
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="f_widget about-widget">
                <h3 className="f-title f_600 w_color f_size_18 mb_40">
                  Quick Links
                </h3>
                <ul className="list-unstyled f_list">
                  {FooterData.aboutLinks.map((links) => {
                    return (
                      <li key={links.id}>
                        <Link to={links.url}>{links.item}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="f_widget about-widget">
                <h3 className="f-title f_600 w_color f_size_18 mb_40">
                  Support
                </h3>
                <ul className="list-unstyled f_list">
                  {FooterData.helpLinks.map((links, list) => {
                    return (
                      <li key={links.id}>
                        <h6 style={{ color: "#fff" }}>{links.item}</h6>
                        <span key={list.id}>{links.item2}</span>
                        <span key={links.id}>{links.item3}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <p className="mt_40">All rights reserved.© 2021 MoveBot</p>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="f_widget social-widget">
                <h3 className="f-title f_600 w_color f_size_18 mb_40">
                  Follow Us
                </h3>
                <div className="f_social_icon">
                  {FooterData.socialLinks.map((item) => {
                    return (
                      <a href={item.url} key={item.id}>
                        <i className={item.icon}></i>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PrototypeFooter;
