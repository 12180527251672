import React from "react";
import { Space, Typography } from "antd";
import { FormikErrors } from "formik";
import { isString, isArray } from "lodash";

export interface IFormItemErrorProps {
  message?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

const FormItemError: React.FC<IFormItemErrorProps> = (props) => {
  const { message } = props;
  const renderString = (str: string) => (
    <Typography.Text type="danger">{str}</Typography.Text>
  );

  return isString(message) ? (
    renderString(message)
  ) : isArray(message) ? (
    <Space direction="vertical">
      {message.map((item) => (isString(item) ? renderString(item) : null))}
    </Space>
  ) : null;
};

export default FormItemError;
