import React from "react";

const TermsOfServiceItemThree = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
      
                To access the Movebot Platform the User shall sign up and or log
                in using the method made available through the Platform.
       
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.7s">
          
                By signing up or logging in, the User is deemed to have accepted
                these General Terms.
            
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.9s">
         
                The User shall ensure that any information submitted upon sign
                up or login is true, accurate, and complete and that third-party
                accounts used for such signup or log in belong to the User.
              
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.9s">
           
                If a User suspects that his/her credit card or other payment
                method associated with the Movebot Platform has been stolen
                and/or is being fraudulently used by a third party, the User
                shall inform Movebot immediately.
            
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemThree;
