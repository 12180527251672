import { OutgoingHttpHeaders } from "http";
import Cookies from "js-cookie";
import { EndpointError } from "../global/errors";
import { cast } from "../global/functions";
import { IEndpointMessageResult } from "./types";

export interface IInvokeEndpointParams<DefaultValue = any> {
  data?: any;
  headers?: OutgoingHttpHeaders;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  defaultValue?: DefaultValue;
  path: string;
  skipProcessing?: boolean;
}

// const TOKEN_EXPIRED_STATUS_CODE = 401;
const HTTP_NOT_FOUND_STATUS_CODE = 404;
export const HTTP_SUCCESS_STATUS_CODE = 200;
export const HTTP_UNAUTHORIZED_STATUS_TEXT = "Unauthorized";

export function getEndpointResultMessage(result: any) {
  return result?.Message || result?.message;
}

export async function invokeEndpoint<T>(
  props: IInvokeEndpointParams<T>
): Promise<T> {
  const { data, path, defaultValue } = props;

  try {
    const headers = {
      "Content-Type": "application/json",
      ...(props.headers || {}),
    };

    const result = await fetch(path, {
      headers,
      body: props.skipProcessing ? data : JSON.stringify(data),
      method: props.method || "POST",
      mode: "cors",
    });

    const contentType = result.headers.get("Content-Type");
    const isResultJSON =
      contentType?.includes("application/json") ||
      contentType?.includes("text/json");
    const isResultText = contentType?.includes("text/plain");

    if (result.ok) {
      if (isResultJSON) {
        return cast<T>(await result.json());
      } else if (isResultText) {
        return cast<T>(await result.text());
      }
    }

    if (result.status === HTTP_NOT_FOUND_STATUS_CODE && defaultValue) {
      return defaultValue;
    }

    let message = result.statusText || "Error completing request";

    if (isResultJSON) {
      const body = cast<IEndpointMessageResult>(await result.json());
      message = getEndpointResultMessage(body) || message;
    }

    throw new EndpointError(message, result.status, result.statusText);
  } catch (error) {
    if (error instanceof EndpointError) {
      throw error;
    } else {
      console.error(error);
      throw new Error("Error completing request");
    }
  }
}

export interface IInvokeEndpointWithAuthParams extends IInvokeEndpointParams {
  token?: string;
}

export async function invokeEndpointWithAuth<T>(
  props: IInvokeEndpointWithAuthParams
): Promise<T> {
  const requestToken = props.token || Cookies.get("accessToken");

  if (!requestToken) {
    throw new Error("Please sign-in again to continue");
  }

  const result = invokeEndpoint<T>({
    ...props,
    headers: {
      Authorization: `Bearer ${requestToken}`,
      ...props.headers,
    },
  });

  return result;
}
