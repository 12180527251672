import { message, Typography, Space, Progress, Row, Col, Button } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import React from "react";
import ImageAPI from "../../api/endpoints/image";
import { appColors } from "./theme";
import { defaultTo } from "lodash";
import useIsComponentMounted from "../../hooks/useIsComponentMounted";

export interface IImageUploadProps {
  isGroupImages?: boolean;
  max?: number; // always set isGroupImages to true if max > 1
  id: string;
  onCompleteUpload?: (count: number) => void;
}

interface IUploadImageState {
  files: File[];
  percent: number;
  id: number;
  show: boolean;
}

const IMAGE_JPEG = "image/jpeg";
const IMAGE_PNG = "image/png";
const DEFAULT_MAX_IMAGES = 10;
const MAX_IMAGES = 10;
const PERCENT_100 = 100;
const BYTES = 1024;
const BYTES_KB = 1048576;
const MAX_IMAGE_SIZE = 25 * BYTES ** 2; // 25MB

export function getFileTooLargeMessage(name: string) {
  return (
    <Typography.Text>
      File{" "}
      <Typography.Text strong ellipsis style={{ width: "100px" }}>
        {name}
      </Typography.Text>{" "}
      is larger than{" "}
      <Typography.Text strong>{MAX_IMAGE_SIZE / (BYTES ^ 2)}MB</Typography.Text>
    </Typography.Text>
  );
}

export function getFileNotValidMessage(name: string) {
  return (
    <Typography.Text>
      File{" "}
      <Typography.Text strong ellipsis style={{ width: "100px" }}>
        {name}
      </Typography.Text>{" "}
      is not a <Typography.Text strong>JPG or PNG</Typography.Text> file
    </Typography.Text>
  );
}

function checkFile(file: File) {
  const isJpgOrPng = file.type === IMAGE_JPEG || file.type === IMAGE_PNG;
  const isSizeAcceptable = file.size <= MAX_IMAGE_SIZE;

  if (!isJpgOrPng) {
    message.error(getFileNotValidMessage(file.name));
  }

  if (!isSizeAcceptable) {
    message.error(getFileTooLargeMessage(file.name));
  }

  return isJpgOrPng && isSizeAcceptable;
}

function returnFileSize(size: number) {
  if (size < BYTES) {
    return size + "bytes";
  } else if (size >= BYTES && size < BYTES_KB) {
    return (size / BYTES).toFixed(1) + "KB";
  } else if (size >= BYTES_KB) {
    return (size / BYTES_KB).toFixed(1) + "MB";
  }
}

const ImageUpload: React.FC<IImageUploadProps> = (props) => {
  const { id, onCompleteUpload } = props;
  let max = defaultTo(props.max, DEFAULT_MAX_IMAGES);
  max = max < MAX_IMAGES ? max : MAX_IMAGES;
  let isGroupImages = props.isGroupImages || max > 1;

  const [inputId] = React.useState(() => Math.random().toString());
  const [images, setImages] = React.useState<IUploadImageState[]>([]);
  const { isMounted } = useIsComponentMounted();
  const inputRef = React.useRef<HTMLInputElement>();

  const prependImageState = React.useCallback(
    (image: IUploadImageState) => {
      setImages([image, ...images]);
    },
    [images]
  );

  const updateImageState = React.useCallback(
    (image: IUploadImageState) => {
      if (!isMounted()) {
        return;
      }

      setImages((currentImages) => {
        const newImages = [...currentImages];
        const index = newImages.findIndex((item) => item.id === image.id);

        if (index !== -1) {
          newImages[index] = image;

          if (image.percent === PERCENT_100 && onCompleteUpload) {
            setTimeout(() => {
              onCompleteUpload(newImages.length);
            }, 2000);
          }
        }

        return newImages;
      });
    },
    [isMounted, onCompleteUpload]
  );

  const onChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const files = evt.target.files || [];
      const validFiles: File[] = Array.from(files).filter((item) =>
        checkFile(item)
      );

      if (validFiles.length === 0) {
        return;
      }

      const imageState: IUploadImageState = {
        files: validFiles,
        percent: 0,
        id: Date.now(),
        show: true,
      };

      const onUploadProgress = (progressEvt: ProgressEvent<EventTarget>) => {
        if (progressEvt.lengthComputable) {
          const percent = (progressEvt.total / progressEvt.loaded) * 100;
          setTimeout(() => {
            updateImageState({
              ...imageState,
              percent,
              show: percent < PERCENT_100,
            });
          }, 3000);
        }
      };

      if (isGroupImages) {
        ImageAPI.bulkUploadImages({
          id,
          onUploadProgress,
          data: validFiles,
        });
      } else {
        ImageAPI.uploadImage({
          isGroupImages,
          id,
          onUploadProgress,
          data: validFiles[0],
        });
      }

      prependImageState(imageState);
    },
    [id, isGroupImages, updateImageState, prependImageState]
  );

  const uploadButtonNode = (
    <label htmlFor={inputId}>
      <Button
        disabled={images.length >= max}
        icon={<CloudUploadOutlined />}
        style={{
          color: appColors.primaryColor,
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        Upload image
      </Button>
      <input
        id={inputId}
        type="file"
        accept={`${IMAGE_JPEG}, ${IMAGE_PNG}`}
        multiple={max && max > 1 ? true : false}
        style={{ display: "none" }}
        onChange={onChange}
        ref={inputRef as React.Ref<HTMLInputElement>}
      />
    </label>
  );

  const renderUploadStateName = (image: IUploadImageState) => {
    return image.files.map((file, i) => (
      <Row gutter={16} key={i}>
        <Col span={20}>
          <Typography.Paragraph ellipsis type="secondary" style={{ margin: 0 }}>
            {file.name}
          </Typography.Paragraph>
        </Col>
        <Col span={4}>
          <Typography.Text
            style={{
              display: "inline-block",
              width: "100%",
              textAlign: "right",
            }}
          >
            {returnFileSize(file.size)}
          </Typography.Text>
        </Col>
      </Row>
    ));
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      {uploadButtonNode}
      {images
        // .filter((image) => image.show)
        .map((image) => {
          return (
            <Space
              key={image.id}
              direction="vertical"
              size={4}
              style={{ width: "100%" }}
            >
              {renderUploadStateName(image)}
              <Progress
                key="progress"
                percent={image.percent}
                showInfo={false}
              />
            </Space>
          );
        })}
    </Space>
  );
};

export default ImageUpload;
