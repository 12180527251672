import React from "react";

const TermsOfServiceItemSeven = (props) => {
  let { rClass, Title, description } = props;
  return (
    <div
      className={`row h_analytices_features_item align-items-center ${rClass}`}
    >
      <div className="col-lg-12">
        <div className="h_analytices_content">
          <h2 className="wow fadeInUp" data-wow-delay="0.1s">
            {Title}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            {description}
          </p>
          <ul className="list-unstyled">
            <li className="wow fadeInUp" data-wow-delay="0.5s">
          
                By registering on the Movebot Platform, Movebot grants the User
                a revocable, non-exclusive, non-transferable, non-sublicensable
                license to use the Movebot Platform for the purpose of ordering
                and tracking of the Services.
       
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
         
                All intellectual property rights regarding the software,
                documentation, or information used or developed by or on behalf
                of Movebot during the provision of the information society
                services under these General Terms (incl. the Movebot Platform
                and any material uploaded therein, except personal materials of
                client and service providers) belong to Movebot. The User shall
                not copy, modify, adapt, reverse-engineer, decompile or
                otherwise discover the source code of the Movebot Platform or
                any other software used by Movebot or extract or use any data on
                the Movebot Platform for commercial purposes or any other
                purpose than the intended purpose of the Movebot Platform. The
                User shall use the Movebot Platform solely for his/her personal,
                non-commercial purposes.
           
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.5s">
    
                3. The principles for processing the Users’ and Clients’
                personal data is set out in the Privacy Policy available on the
                Movebot Platform.
        
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServiceItemSeven;
