import React from "react";
import CustomNavbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import Contacts from "../components/Contact/Contacts";
import FooterData from "../components/Footer/FooterData";
import PrototypeFooter from "./../components/Footer/PrototypeFooter";

const About = () => {

  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Contact Us"
        Pdescription="Get in touch with our team"
      />
      <Contacts />
      <PrototypeFooter FooterData={FooterData} />
    </div>
  );
};
export default About;
