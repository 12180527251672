import React from "react";
import { v4 as uuid } from "uuid";
import { removeUnusedImages } from "../components/utils/images";

export interface IUseImageAttachmentIdProps {
  attachmentId?: string;
  manageAttachmentId?: boolean;
  isGroupImage: boolean;
}

export interface IUseImageAttachmentIdResult {
  attachmentId: string;

  // To let the hook know if there are images uploaded with the attachmentId.
  // The hook will not attempt to delete the attachmentId's images on cleanup()
  // if this is called first for a managed (hook-generated) attachmentId.
  updateUploadCount: (updateFn: (amount: number) => number) => void;
  setUploadCount: (count: number) => void;

  // To consume the attachmentId.
  // If generateNewId is true, a new managed attachmentId will be generated, otherwise,
  // the current attachmentId will transition from managed to unmanaged, meaning,
  // the hook will not attempt to delete the attachments on cleanup()
  consumeAttachmentId: (generateNewId?: boolean) => void;

  // To cleanup the uploaded images if the attachmentId is:
  // 1. Managed
  // 2. Has image uploaded
  // 3. Is not consumed with complete()
  cleanup: () => Promise<void>;

  // Generate new attachmentId and cleanup the old one
  generateNewId: () => Promise<string>;
}

/**
 * Provides an attachmentId for uploading images if one is not passed, and
 * provides lifecycle functions for cleaning up the attachment if the attachmentId
 * is managed.
 */
export function useImageAttachmentId(
  props: IUseImageAttachmentIdProps
): IUseImageAttachmentIdResult {
  // Opting to use useState here rather than using useRef so that a new
  // attachmentId can be surfaced through React re-rendering the calling component.
  const [attachmentId, setAttachmentId] = React.useState(
    () => props.attachmentId || uuid()
  );

  // Using useRef for sessionImageUploadCount and isManagedAttachmentId
  // to prevent unnecessary re-renders.

  // sessionImageUploadCount contains the amount of images uploaded between sessions.
  // A session starts when an attachmentId is created (or provided through props),
  // and ends on complete() or on cleanup() or when a new attachmentId is created
  // through generateNewId() or one is passed in through props.
  const sessionImageUploadCount = React.useRef(0);

  // To know if the hook generated the attachmentId, and to delete the images
  // if it is not consumed with complete() on cleanup()
  const isManagedAttachmentId = React.useRef(
    !props.attachmentId || props.manageAttachmentId
  );
  const setUploadCount = React.useCallback((count: number) => {
    sessionImageUploadCount.current = count;
  }, []);

  const updateUploadCount = React.useCallback(
    (updateFn: (amount: number) => number) => {
      setUploadCount(updateFn(sessionImageUploadCount.current));
    },
    [setUploadCount]
  );

  const complete = React.useCallback((generateNewId?: boolean) => {
    // Start a new session
    sessionImageUploadCount.current = 0;

    if (generateNewId) {
      setAttachmentId(uuid());
      isManagedAttachmentId.current = true;
    } else {
      // Transition from managed to unmanaged.
      // Mark attachmentId no longer managed to prevent the attachment
      // from being deleted on cleanup()
      isManagedAttachmentId.current = false;
    }
  }, []);

  const cleanup = React.useCallback(async () => {
    if (isManagedAttachmentId.current && sessionImageUploadCount.current > 0) {
      await removeUnusedImages({
        id: attachmentId,
        isGroupImage: props.isGroupImage,
      });

      // Only updating sessionImageUploadCount and not isManagedAttachmentId cause the attachmentId
      // is still managed and can still be used to upload images
      sessionImageUploadCount.current = 0;
    }
  }, [attachmentId, props.isGroupImage]);

  const generateNewId = React.useCallback(async () => {
    await cleanup();

    const newId = uuid();
    setAttachmentId(newId);
    isManagedAttachmentId.current = true;
    sessionImageUploadCount.current = 0;
    return newId;
  }, [cleanup]);

  React.useEffect(() => {
    // Update attachmentId and other artifacts when a new attachmentId is provided
    if (props.attachmentId && props.attachmentId !== attachmentId) {
      cleanup();
      setAttachmentId(props.attachmentId);
      isManagedAttachmentId.current = false;
      sessionImageUploadCount.current = 0;
    }
  }, [props.attachmentId, attachmentId, cleanup]);

  // cleanup image when the user closes the form without saving
  React.useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return {
    attachmentId,
    updateUploadCount,
    cleanup,
    generateNewId,
    setUploadCount,
    consumeAttachmentId: complete,
  };
}
