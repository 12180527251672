import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import axios from "axios";
import * as yup from "yup";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import "./Partner.css";
import appVariables from "../../variables";
import { getEndpointResultMessage } from "../../api/invokeREST";
import { Alert } from "antd";
import { css } from "@emotion/css";

const messages = {
  loadCountriesError:
    "Error loading available countries, please reload the page",
  requiredField: "Field is required",
  countryCodeRequired: "Please select country code",
  requestError: "Error completing request",
  invalidEmail: "Please enter a valid email address",
};

const validationSchema = yup.object().shape({
  companyName: yup.string().required(messages.requiredField),
  firstName: yup.string().required(messages.requiredField),
  lastName: yup.string().required(messages.requiredField),
  emailAddress: yup
    .string()
    .email(messages.invalidEmail)
    .required(messages.requiredField),
  phoneNumber: yup.string().required(messages.requiredField),
  countryCode: yup.string().required(messages.countryCodeRequired),
  jobTitle: yup.string().required(messages.requiredField),
  memo: yup.string(),
  street: yup.string().required(messages.requiredField),
  city: yup.string().required(messages.requiredField),
  state: yup.string().required(messages.requiredField),
  zipCode: yup.string(),
  country: yup.string().required(messages.requiredField),
});

const classes = {
  alertContainer: css({
    display: "flex",
    justifyContent: "center",
    width: "100%",

    "@media (min-width: 1148px)": {
      marginTop: "48px !important",
    },

    "@media (max-width: 767px)": {
      marginTop: "16px !important",
    },
  }),
  alert: css({
    "@media (min-width: 1148px)": {
      width: "1100px",
    },

    "@media (max-width: 767px)": {
      width: "100%",
    },
  }),
};

const initialValues = {
  companyName: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  countryCode: "",
  jobTitle: "",
  hasCACNo: false,
  memo: "",
  street: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
};

const Partner = () => {
  const url = `${appVariables.serverURL}/LandingPage/PartnerWithUs`;
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [countries, setCountries] = useState();
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadCountriesError, setLoadCountriesError] = useState("");
  const [isLoading, setLoading] = useState(false);

  // Load countries
  useEffect(() => {
    setLoadingCountries(true);
    setLoadCountriesError("");
    axios
      .get(`${appVariables.serverURL}/Resources/GetCountryCodeObj`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((error) => {
        if (error.response) {
          setLoadCountriesError(getEndpointResultMessage(error.response.data));
        } else {
          setLoadCountriesError(messages.loadCountriesError);
        }
      })
      .finally(() => {
        setLoadingCountries(false);
      });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        companyName,
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        countryCode,
        jobTitle,
        hasCACNo,
        memo,
      } = values;

      const payload = {
        companyName,
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        countryCode,
        jobTitle,
        hasCACNo,
        memo,
        address: {
          street: values.street,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
        },
      };

      setLoading(true);
      setError("");
      setSuccessMessage("");
      axios
        .post(url, payload)
        .then((res) => {
          setSuccessMessage(getEndpointResultMessage(res.data));
        })
        .catch((error) => {
          if (error.response) {
            setError(getEndpointResultMessage(error.response.data));
          } else {
            setError(messages.requestError);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="page-content">
      {loadingCountries && (
        <div className={classes.alertContainer}>
          <Alert
            icon={<LoadingOutlined />}
            type="info"
            message="Loading available countries..."
            className={classes.alert}
          />
        </div>
      )}
      {loadCountriesError && (
        <div className={classes.alertContainer}>
          <Alert
            type="error"
            message={loadCountriesError}
            className={classes.alert}
          />
        </div>
      )}
      {error && (
        <div className={classes.alertContainer}>
          <Alert type="error" message={error} className={classes.alert} />
        </div>
      )}
      {successMessage && (
        <div className={classes.alertContainer}>
          <Alert
            type="success"
            message={successMessage}
            className={classes.alert}
          />
        </div>
      )}
      <div className="form-v10-content">
        <form onSubmit={formik.handleSubmit} className="form-detail">
          <div className="form-left">
            <h2>General Infomation</h2>

            <div className="form-row">
              <input
                type="text"
                name="companyName"
                className="companyName"
                id="companyName"
                placeholder="Company Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                disabled={isLoading}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <ErrorDiv>{formik.errors.companyName}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-group">
              <div className="form-row form-row-1">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="input-text"
                  placeholder="First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  disabled={isLoading}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <ErrorDiv>{formik.errors.firstName}</ErrorDiv>
                ) : null}
              </div>
              <div className="form-row form-row-2">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="input-text"
                  placeholder="Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  disabled={isLoading}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <ErrorDiv>{formik.errors.lastName}</ErrorDiv>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <input
                type="text"
                name="emailAddress"
                id="emailAddress"
                className="input-text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailAddress}
                placeholder="Email Address"
                disabled={isLoading}
              />
              {formik.touched.emailAddress && formik.errors.emailAddress ? (
                <ErrorDiv>{formik.errors.emailAddress}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-row">
              <input
                type="text"
                name="memo"
                className="memo"
                id="memo"
                placeholder="Note"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.memo}
                disabled={isLoading}
              ></input>
              {formik.touched.memo && formik.errors.memo ? (
                <ErrorDiv>{formik.errors.memo}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-group">
              <p style={{ padding: "10px 10px", marginLeft: "10px" }}>
                Is the company name above duly registered with the government
                authority e.g CAC?
              </p>
              <div className="form-row form-row-4">
                <select
                  name="hasCACNo"
                  id="hasCACNo"
                  onChange={(evt) =>
                    formik.setFieldValue(
                      "hasCACNo",
                      evt.target.value === "true"
                    )
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.hasCACNo}
                  disabled={isLoading}
                >
                  <option value={true}>YES</option>
                  <option value={false}>NO</option>
                </select>
                {formik.touched.hasCACNo && formik.errors.hasCACNo ? (
                  <ErrorDiv>{formik.errors.hasCACNo}</ErrorDiv>
                ) : null}

                <span className="select-btn">
                  <i className="zmdi zmdi-chevron-down"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="form-right">
            <h2>Contact Details</h2>
            <div className="form-row">
              <input
                type="text"
                name="street"
                className="street"
                id="street"
                placeholder="Street"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
                disabled={isLoading}
              />
              {formik.touched.street && formik.errors.street ? (
                <ErrorDiv>{formik.errors.street}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-row">
              <input
                type="text"
                name="city"
                className="city"
                id="city"
                placeholder="City"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                disabled={isLoading}
              />
              {formik.touched.city && formik.errors.city ? (
                <ErrorDiv>{formik.errors.city}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-row">
              <input
                type="text"
                name="state"
                className="state"
                id="state"
                placeholder="State"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                disabled={isLoading}
              />
              {formik.touched.state && formik.errors.state ? (
                <ErrorDiv>{formik.errors.state}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-group">
              <div className="form-row form-row-1">
                <input
                  type="text"
                  name="zipCode"
                  className="zip"
                  id="zipCode"
                  placeholder="Zip Code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.zipCode}
                  disabled={isLoading}
                />
              </div>
              <div className="form-row form-row-2">
                <div>
                  <input
                    type="text"
                    name="country"
                    className="country"
                    id="country"
                    placeholder="Country"
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    disabled={isLoading}
                  />
                </div>
                {formik.touched.country && formik.errors.country ? (
                  <ErrorDiv>{formik.errors.country}</ErrorDiv>
                ) : null}
              </div>
            </div>
            <div className="form-group">
              <div className="form-row form-row-1">
                <select
                  name="countryCode"
                  id="countryCode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.countryCode}
                  disabled={isLoading}
                >
                  <option key="empty" value="">
                    Country code
                  </option>
                  {countries
                    ? countries.map((country) => {
                        return (
                          <option key={country.Code} value={country.Code}>
                            {country.Code} {country.NoCode_}
                          </option>
                        );
                      })
                    : null}
                </select>
                <span className="select-btn">
                  <i className="zmdi zmdi-chevron-down"></i>
                </span>
                {formik.touched.countryCode && formik.errors.countryCode ? (
                  <ErrorDiv>{formik.errors.countryCode}</ErrorDiv>
                ) : null}
              </div>
              <div className="form-row form-row-2">
                <input
                  type="text"
                  name="phoneNumber"
                  className="phone"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  disabled={isLoading}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <ErrorDiv>{formik.errors.phoneNumber}</ErrorDiv>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <input
                type="text"
                name="jobTitle"
                className="jobTitle"
                id="jobTitle"
                placeholder="Job Title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.jobTitle}
                disabled={isLoading}
              />
              {formik.touched.jobTitle && formik.errors.jobTitle ? (
                <ErrorDiv>{formik.errors.jobTitle}</ErrorDiv>
              ) : null}
            </div>
            <div className="form-row-last">
              <button
                style={{ padding: "12px 12px" }}
                type="submit"
                name="register"
                className="register movebot-icon-btn"
                disabled={isLoading}
              >
                {isLoading && <LoadingOutlined />}
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const ErrorDiv = styled.div`
  color: #ff7875;
  margin-left: 16px;
`;

export default Partner;
